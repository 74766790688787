// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2023/6/27
// +-------------------------------------------------------------
// |Time: 10:42
// +-------------------------------------------------------------
export default [
    {
        path: '/game/app-game',
        name: "AppGameList",
        meta: {
            title: "游戏列表",
        },
        component: () => import("@/views/game/AppGame/List")
    },
    {
        path: '/game/platform-game/:game_id',
        name: "PlatformGameList",
        meta: {
            title: "平台游戏",
        },
        component: () => import("@/views/game/PlatformGame/List")
    },
    {
        path: '/data/user-white',
        name: "UserWhiteList",
        meta: {
            title: "用户折扣",
        },
        component: () => import("@/views/data/userWhite/List")
    },
    {
        // 采用不显示传参处理
        path: "/data/user-white-operate",
        name: "UserWhiteOperate",
        meta: {
            title: "编辑/添加用户折扣",
            menu:true,
        },
        component: () => import("@/views/data/userWhite/Operate.vue")
    },
    {
        path: '/order/game-recharge-order',
        name: "GameRechargeOrder",
        meta: {
            title: "订单明细",
        },
        component: () => import("@/views/order/gameRechargeOrder/List")
    },
    {
        path: '/data/user',
        name: "DataUserList",
        meta: {
            title: "注册明细",
        },
        component: () => import("@/views/data/user/List")
    },
    {
        path: '/order/game-recharge-settle',
        name: "GameRechargeSettle",
        meta: {
            title: "结算明细",
        },
        component: () => import("@/views/order/gameRechargeSettle/List")
    },
    {
        path: '/agent/promote-discount',
        name: "AgentPromoteDiscount",
        meta: {
            title: "子渠道加点",
        },
        component: () => import("@/views/agent/childPromoteDiscount/List")
    },
    {
        path: "/agent/promote-discount-operate",
        name: "AgentPromoteDiscountOperate",
        meta: {
            title: "编辑/添加子渠道加点",
            menu:true,
        },
        component: () => import("@/views/agent/childPromoteDiscount/Operate.vue")
    },
    {
        path: '/agent/promote-list',
        name: "AgentPromoteList",
        meta: {
            title: "子渠道列表",
        },
        component: () => import("@/views/agent/childPromote/List")
    },
    {
        path: '/agent/recharge-game',
        name: "AgentRechargeGame",
        meta: {
            title: "代充充值",
        },
        component: () => import("@/views/agent/rechargeGame/Index.vue")
    },
]
